<template>
  <div>
    <div class="row w-100">
      <div id="headerHome" class="col-sm-12 col-md-12 color-bg-4">
        <HeaderMenu />
      </div>
    </div>
    <div class="header-pol-priv">AVISO DE PRIVACIDAD</div>
    <div class="politica-cont">
      <!--<div class="titulo-pol">AVISO DE <span>PRIVACIDAD</span></div>--->
      <p class="texto-pol">
        <b>AGRIGA DE MEXICO SA DE CV</b>, empresa legalmente constituida de
        conformidad con las leyes mexicanas con domicilio fiscal en Blvd.
        Enrique Mazon 445 B, Col. San Luis, en la ciudad de Hermosillo, Sonora,
        es responsable de recabar sus datos personales, del uso que se le de a
        los mismos y de su protección.
      </p>

      <p class="texto-pol t-pol">Fines de uso de información recabada</p>

      <p class="texto-pol">
        Los datos personales que recabamos de usted, los utilizaremos para las
        siguientes finalidades que son necesarias para el servicio que solicita:
      </p>
      <p class="texto-pol lista-pol first">
        •Informar sobre promociones, actividades y/o eventos
      </p>
      <p class="texto-pol lista-pol">
        • Evaluar calidad en el servicio. De manera adicional, utilizaremos su
        información personal para las siguientes finalidades secundarias que no
        son necesarias para el servicio solicitado, pero que nos permiten y
        facilitan brindarle una mejor atención:
      </p>
      <p class="texto-pol lista-pol last">• Mercadotecnia y/o publicidad.</p>

      <p class="texto-pol">
        Los datos que podemos recabar para los fines mencionados en el presente
        Aviso de Privacidad Integral son los siguientes:
      </p>

      <p class="texto-pol lista-pol first">• Nombre.</p>
      <p class="texto-pol lista-pol">• Correo electrónico.</p>
      <p class="texto-pol lista-pol">• Dirección.</p>
      <p class="texto-pol lista-pol last">• Teléfono.</p>

      <p class="texto-pol">
        Sus datos podrán ser proporcionados a terceros única y exclusivamente a
        fabricantes y/o proveedores, con los que
        <b>AGRIGA DE MEXICO SA DE CV</b>
        mantiene una relación lícita y ética de negocios, para efectos de
        Marketing siempre con las limitaciones señaladas en la Ley Federal de
        Protección de Datos Personales en Posesión de Particulares.
      </p>

      <p class="texto-pol">
        Todos sus datos personales son tratados con estricta confidencialidad de
        acuerdo a la legislación aplicable y vigente en el país, por ello le
        informamos que usted tiene en todo momento los derechos para el Acceso,
        Rectificación, Cancelación u Oposición al tratamiento que le damos a sus
        datos personales (Derechos ARCO), los cuales usted o su representante
        legal podrán hacer valer elaborando la “Solicitud Ejercicio de los
        Derechos ARCO” y enviarla escaneada dirigida a nuestra área de
        Protección de Datos al correo electrónico contacto@agriga.com.mx o por
        escrito en cualquiera de nuestras sucursales. Es importante que
        considere los siguientes puntos al momento de enviar o entregar su
        solicitud:
      </p>

      <p class="texto-pol lista-pol first">
        • Indicar su nombre, domicilio y correo electrónico para poder
        comunicarle la respuesta a su solicitud.
      </p>
      <p class="texto-pol lista-pol">
        • Indicar los datos personales respecto de los que se busca ejercer
        alguno de los derechos.
      </p>
      <p class="texto-pol lista-pol">
        • Anexar cualquier documento o información que facilite la localización
        de sus datos personales.
      </p>
      <p class="texto-pol lista-pol last">
        • Anexar copia del documento que acredite su identidad (credencial de
        elector, cédula profesional o pasaporte vigente).
      </p>

      <p class="texto-pol t-pol">Cambios al aviso de privacidad</p>

      <p class="texto-pol">
        <b>AGRIGA DE MEXICO SA DE CV</b> se reserva el derecho de modificar en
        cualquier momento y sin previo aviso el presente aviso de privacidad por
        actualizaciones legislativas, jurisprudenciales, políticas internas,
        nuevos requisitos para la venta de productos en nuestra Tienda Online
        y/o cualquier otra causa. En tal caso, las modificaciones estarán
        disponibles en nuestra página de internet www.agriga.com.mx, por lo que
        le recomendamos verificar periódicamente en caso de que existan cambios.
      </p>
    </div>
    <div class="w-100">
      <div id="footer" class="color-bg-4">
        <Footer />
      </div>
    </div>
  </div>
</template>


<script>
import HeaderMenu from "../components/fixedItems/HeaderMenu.vue";
import Footer from "../components/fixedItems/Footer.vue";
export default {
  name: "aviso",
  components: {
    HeaderMenu,
    Footer,
  },
};
</script>


<style scoped>
.header-pol-priv {
  width: 100%;
  height: 18.385vw;
  background-image: url("../assets/aviso-priv.png");
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 2.344vw;
  line-height: 99.5%;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.politica-cont {
  padding: 6.302vw 15.521vw;
}
p.texto-pol {
  font-size: 1.042vw;
  line-height: 194.5%;
  text-align: justify;
  color: #a0a0a0;
  margin: 0 0 2.2552vw;
}
p.texto-pol.t-pol{
  margin: 2.2552vw 0 0;
  text-transform: uppercase;
  font-weight: 600;
}
.titulo-pol {
  font-size: 2.344vw;
  line-height: 2.708vw;
  color: #000000;
  font-family: "Lato";
  margin-bottom: 3vw;
}
.titulo-pol span {
  color: #000000;
  font-family: "Lato";
}
p.texto-pol.lista-pol {
  margin: 0;
}
p.texto-pol.lista-pol.last {
  margin: 0 0 2.344vw; 
}

@media (max-width: 768px) {
  .header-pol-priv {
    width: auto;
    height: 48.832vw;
    background-image: url("../assets/aviso-priv-2.png");
    font-size: 8.178vw;
    padding: 0 23.131vw;
  }
  .politica-cont {
    padding: 23.598vw 16.121vw 26.869vw;
  }
  .titulo-pol {
    font-size: 4.831vw;
    line-height: 4.831vw;
    margin-bottom: 12.077vw;
  }
  p.texto-pol {
    font-size: 2.899vw;
    line-height: 179.5%;
    margin: 4.2552vw 0;
  }
}
</style>
